import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { UserStorageService } from '../services/storage/user-storage.service';
import { Role } from '../enum/roles';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {

  private get currentUser(): User {
    return this.userStorageService.get();
  }

  constructor(private userStorageService: UserStorageService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.currentUser && this.currentUser.role === Role.Admin) {
      return true;
    }

    return this.router.parseUrl('/login');
  }
}
