import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  hasError = false;

  private email: null;
  private token: null;

  constructor(
    private route: ActivatedRoute,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.email = params.email;
      this.token = params.token;
    });

    this.apiClientService.verify(this.token).subscribe(
      response => this.handleVerifyResponse(response),
      error => this.handleError(error)
    );
  }

  onResend() {
    this.apiClientService.resendVerification(this.token).subscribe(
      response => this.handleResendVerificationResponse(response),
      error => this.handleError(error)
    );
  }

  private handleVerifyResponse(response) {
    console.log();
    if (response.status === 'error') {
      this.hasError = true;
    } else {
      const state = {
        email: this.email,
        token: this.token
      };
      this.router.navigateByUrl('/set-password', {state});
    }
  }

  private handleResendVerificationResponse(response) {
    localStorage.setItem('checkbox-referrer', 'verify');
    this.router.navigateByUrl('/check-inbox');
  }

  private handleError(error) {
    this.hasError = true;
  }
}
