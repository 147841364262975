import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-questions-form',
  templateUrl: './questions-form.component.html',
  styleUrls: ['./questions-form.component.scss']
})
export class QuestionsFormComponent implements OnInit, OnChanges {

  @Input() questions: {
    id: any,
    question: string,
    placeholder: string
    answer: string
  }[];

  answers: {}[] = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.answers = [];
    // if (this.questions.length > 0) {
    //   this.answers = this.questions.map(question => {
    //     return {id: question.id, value: null};
    //   });
    //   console.log(this.answers);
    // }
  }
}
