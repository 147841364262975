import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../services/user/user.service';
import { Router } from '@angular/router';
import { ApiClientService } from '../../../../services/shared/api-client.service';

@Component({
  selector: 'app-setup-summary',
  templateUrl: './setup-summary.component.html',
  styleUrls: ['./setup-summary.component.scss']
})
export class SetupSummaryComponent implements OnInit {

  avatarUrl = null;
  qrCode = null;
  mood = null;

  tabs: string[] = ['My Mood', 'About Me'];
  selectedTab = this.tabs[0];

  user = null;

  @ViewChild('avatar', {static: false}) private avatar: ElementRef;

  constructor(
    private userService: UserService,
    private router: Router,
    private apiClientService: ApiClientService
  ) { }

  ngOnInit() {
    this.mood = history.state.mood;
    this.apiClientService.user().subscribe(
      response => this.handleGetUserResponse(response),
      error => this.handleError(error)
    );
    this.apiClientService.getMood().subscribe(
      response => this.handleGeMoodResponse(response),
      error => this.handleError(error)
    );
  }

  onComplete() {
    this.router.navigateByUrl('/dashboard');
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    this.preview(file);
    this.apiClientService.setAvatar(file).subscribe(
      response => this.handleSetAvatarResponse(response),
      error => this.handleError(error)
    );
  }

  preview(file) {

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.avatarUrl = reader.result;
      // this.avatar.nativeElement.setAttribute('style', 'background-image: url(' + this.previewUrl + ')');
    };
  }

  onLogout() {
    this.userService.logout();
    //this.router.navigateByUrl('/login');
    location.href = 'login';
  }

  private handleGeMoodResponse(response) {
    this.mood = response.data[0];
  }

  private handleGetUserResponse(response) {
    console.log(response);
    this.user = response;
    this.avatarUrl = this.user.image ? this.user.image.url : '/images/profile-pic.svg';
    this.qrCode = 'data:image/jpeg;base64,' + this.user.qr_code;
  }

  private handleSetAvatarResponse(response) {
    console.log(response);
  }

  private handleError(error) {
    console.log(error);
  }
}
