import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QrreaderComponent } from './components/shared/qrreader/qrreader.component';
import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { LoginComponent } from './components/users/login/login.component';
import { SignupComponent } from './components/users/signup/signup.component';
import { CheckInboxComponent } from './components/users/check-inbox/check-inbox.component';
import { SetPasswordComponent } from './components/users/set-password/set-password.component';
import { ForgottenPasswordComponent } from './components/users/forgotten-password/forgotten-password.component';
import { SetupPhotoComponent } from './components/patient/setup/photo/setup-photo.component';
import { SetupQuestionsComponent } from './components/patient/setup/questions/setup-questions.component';
import { SetupMoodComponent } from './components/patient/setup/mood/setup-mood.component';
import { SetupSummaryComponent } from './components/patient/setup/summary/setup-summary.component';
import { PatientAboutMeComponent } from './components/patient/about-me/patient-about-me.component';
import { MoodsGridComponent } from './components/shared/moods-grid/moods-grid.component';
import { QuestionsFormComponent } from './components/shared/questions-form/questions-form.component';
import { VerifyComponent } from './components/users/verify/verify.component';
import { CareRecipientDetailsComponent } from './components/users/care-recipient-details/care-recipient-details.component';
import { PatientMyMoodComponent } from './components/patient/my-mood/patient-my-mood.component';
import { SharedModule } from './modules/shared/shared.module';
import { PatientProfileComponent } from './components/staff/patient-profile/patient-profile.component';
import { StaffProfileComponent } from './components/admin/staff-profile/staff-profile.component';
import { InviteComponent } from './components/admin/invite/invite.component';
import { ScanComponent } from './components/staff/scan/scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ResetPasswordComponent } from './components/users/reset-password/reset-password.component';
import { ManageAccountComponent } from './components/patient/manage-account/manage-account.component';
import { EditDetailsComponent } from './components/admin/edit-details/edit-details.component';
import { ChangePasswordComponent } from './components/users/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    QrreaderComponent,
    PagenotfoundComponent,
    LoginComponent,
    SignupComponent,
    CheckInboxComponent,
    SetPasswordComponent,
    ForgottenPasswordComponent,
    SetupPhotoComponent,
    SetupQuestionsComponent,
    SetupMoodComponent,
    SetupSummaryComponent,
    PatientMyMoodComponent,
    PatientAboutMeComponent,
    MoodsGridComponent,
    QuestionsFormComponent,
    VerifyComponent,
    CareRecipientDetailsComponent,
    PatientProfileComponent,
    StaffProfileComponent,
    InviteComponent,
    ScanComponent,
    ResetPasswordComponent,
    ManageAccountComponent,
    EditDetailsComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxQRCodeModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ZXingScannerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
