import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodsGridComponent } from '../../../shared/moods-grid/moods-grid.component';
import { ApiClientService } from '../../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup-mood',
  templateUrl: './setup-mood.component.html',
  styleUrls: ['./setup-mood.component.scss']
})
export class SetupMoodComponent implements OnInit {

  @ViewChild('moodsGrid', {static: false}) private moodsGrid: MoodsGridComponent;

  selectedMood: {
    id: number;
  } = null;

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() {}

  onNext() {
    this.apiClientService.setMood(this.selectedMood.id).subscribe(
      data => this.handleSetMoodResponse(data),
      error => this.handleError(error)
    );
  }

  private handleSetMoodResponse(response) {
    const state = {
      mood: this.selectedMood
    };
    this.router.navigateByUrl('/setup/summary', { state });
  }

  private handleError(error) {
    console.log(error);
  }

  onSelectMood(mood) {
    console.log(`onSelectMood ${mood}`);
    this.selectedMood = mood;
  }
}
