import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-inbox',
  templateUrl: './check-inbox.component.html',
  styleUrls: ['./check-inbox.component.scss']
})
export class CheckInboxComponent implements OnInit {

  public message;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('checkbox-referrer') == 'register') {
      this.message = 'We have sent a verification link to your email address. Please check your Inbox';
    } else {
      this.message = 'If your email address is registered with us, we will send a verification link to your Inbox.';
    }
  }

  onBack() {
    this.router.navigateByUrl('/login');
  }
}
