import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-care-recipient-details',
  templateUrl: './care-recipient-details.component.html',
  styleUrls: ['./care-recipient-details.component.scss']
})
export class CareRecipientDetailsComponent implements OnInit {

  public errors = {
    firstName: [],
    lastName: []
  };

  public form = {
    firstName: null,
    lastName: null
  };

  private guardianData: null;

  private get isValid(): boolean {

    let result = true;

    if (this.form.firstName === null || this.form.firstName === '') {
      this.errors.firstName.push('Please provide patient\'s First Name');
      result = false;
    }

    if (this.form.lastName === null || this.form.lastName === '') {
      this.errors.lastName.push('Please provide patient\'s Last Name');
      result = false;
    }

    return result;
  }

  constructor(private userService: ApiClientService, private router: Router) { }

  ngOnInit() {
    this.guardianData = history.state.guardianData;
  }

  onSend() {

    this.clearErrors();

    if (this.isValid) {
      const form = this.guardianData;
      // @ts-ignore
      form.guardian = this.form;
      this.userService.signup(form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
    }
  }

  private handleResponse(data) {
    localStorage.setItem('checkbox-referrer', 'register');
    this.router.navigateByUrl('/check-inbox');
  }

  private handleError(error) {
    this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      firstName: [],
      lastName: []
    };
  }
}
