import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../../../services/shared/api-client.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  images: {}[] = [];

  constructor(private apiClientService: ApiClientService) { }

  ngOnInit() {
    this.fetchGallery();
  }

  onAdd() { }

  onFileChanged(event) {
    const file = event.target.files[0];
    this.apiClientService.upload(file).subscribe(
      response => this.handleUploadResponse(response),
      error => this.handleError(error)
    );
  }

  private handleUploadResponse(response) {
    this.fetchGallery();
  }

  private fetchGallery() {
    this.apiClientService.gallery().subscribe(
      response => this.handleGetGalleryResponse(response),
      error => this.handleError(error)
    );
  }

  private handleGetGalleryResponse(response) {
    console.log(response);
    this.images = response.data;
  }

  private handleError(error) {
    console.log(error);
  }
}
