import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../../services/storage/token-storage.service';
import { UserStorageService } from '../../../services/storage/user-storage.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  public errors = {
    password: [],
    passwordConfirmation: []
  };

  public form = {
    password: null,
    passwordConfirmation: null
  };

  private email: null;
  private token: null;

  private get isValid(): boolean {

    let result = true;

    if (this.form.password === null || this.form.password === '') {
      this.errors.password.push('Please provide your Password');
      result = false;
    }

    if (this.form.passwordConfirmation === null || this.form.passwordConfirmation === '') {
      this.errors.passwordConfirmation.push('Please provide your Password Confirmation');
      result = false;
    }

    if (this.form.password !== this.form.passwordConfirmation) {
      this.errors.password.push('Passwords do not match');
      this.errors.passwordConfirmation.push('Passwords do not match');
      result = false;
    }

    return result;
  }

  constructor(
    private apiClientService: ApiClientService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private userStorageService: UserStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.email = history.state.email;
    this.token = history.state.token;
  }

  onNext() {

    this.clearErrors();

    if (this.isValid) {
      const data = {
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
        token: this.token,
        email: this.email
      };
      this.apiClientService.setPassword(data).subscribe(
        response => this.handleResponse(response),
        error => this.handleError(error)
      );
    }
  }

  private handleResponse(response) {
    this.tokenStorageService.set(response.access_token);
    this.userStorageService.set(response.user);

    if (this.userService.isClient) {
      this.router.navigateByUrl('/setup/photo');
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  private handleError(error) {
    this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      password: [],
      passwordConfirmation: []
    };
  }
}
