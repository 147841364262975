import { Component, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Router } from '@angular/router';
import { ApiClientService } from '../../../services/shared/api-client.service';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit {

  result: string;
  guid = null;

  @ViewChild('questionsForm', {static: false}) private scanner: ZXingScannerComponent;

  constructor(private router: Router, private apiClientService: ApiClientService,) { }

  ngOnInit() { }

  camerasFoundHandler(event) {

  }

  scanCompleteHandler(event) {
    this.result = event;
    if (this.isValidHttpUrl(this.result)) {
      this.guid = [...this.result.split('/')].pop();
      if (this.guid) {
        this.apiClientService.scan(this.guid).subscribe(
          response => this.handleGetPatientResponse(response),
          error => this.handleError(error)
        );
      }
    }
  }

  private handleGetPatientResponse(response) {
    console.log(response);
    this.router.navigateByUrl(`/patient/${this.guid}`);
  }

  private handleError(error) {
    console.log(error);
  }

  private isValidHttpUrl(value) {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}
