import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Constants } from '../../model/constants';
import { Observable, of } from 'rxjs';
import { UserStorageService } from '../storage/user-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(private apiService: ApiService, private userStorageService: UserStorageService) {}

  signup(data) {
    let body = {};
    if (data.isGuardian === '1') {
      body = {
        first_name: data.guardian.firstName,
        last_name: data.guardian.lastName,
        email: data.email,
        is_guardian: data.isGuardian,
        guardian: {
          first_name: data.firstName,
          last_name: data.lastName,
        }
      };
    } else {
      body = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        is_guardian: data.isGuardian
      };
    }
    return this.apiService.post(Constants.Endpoint.register, body);
  }

  login(data) {
    return this.apiService.post(Constants.Endpoint.login, data);
  }

  forgotPassword(data) {
    const body = {
      email: data.email,
    };
    return this.apiService.post(Constants.Endpoint.forgotPassword, body);
  }

  verifyForgotPassword(data) {
    const body = {
      token: data.token,
      email: atob(data.email)
    };
    return this.apiService.post(Constants.Endpoint.forgotPasswordVerify, body);
  }

  resetPassword(data) {
    const body = {
      password: data.password,
      password_confirmation: data.password_confirmation,
      token: data.token,
      email: atob(data.email)
    };
    return this.apiService.post(Constants.Endpoint.resetPassword, body);
  }

  setPassword(data) {
    const body = {
      password: data.password,
      password_confirmation: data.password_confirmation,
      token: data.token,
      email: atob(data.email)
    };
    return this.apiService.post(Constants.Endpoint.setPassword, body);
  }

  verify(token) {
    return this.apiService.get(Constants.Endpoint.verify(token));
  }

  resendVerification(data) {
    const body = {
      token: data.token
    };
    return this.apiService.post(Constants.Endpoint.resendVerification, body);
  }

  questions() {
    return this.apiService.get(Constants.Endpoint.questions);
  }

  answers(data) {
    const body = {
      questions: data
    };
    return this.apiService.post(Constants.Endpoint.answers(this.userStorageService.get().guid), body);
  }

  getMoods() {
    return this.apiService.get(Constants.Endpoint.moods);
  }

  setMood(data): Observable<boolean> {
    const body = {
      mood: data
    };
    return this.apiService.post(Constants.Endpoint.myMood(this.userStorageService.get().guid), body);
  }

  getMood(guid = null) {
    return this.apiService.get(Constants.Endpoint.myMood(guid ? guid : this.userStorageService.get().guid));
  }

  setAvatar(data) {
    const userId = this.userStorageService.get().guid;
    const formData: FormData = new FormData();
    formData.append('avatar', data, data.name);
    return this.apiService.postFormData(Constants.Endpoint.setAvatar(userId), formData);
  }

  user() {
    const userId = this.userStorageService.get().guid;
    return this.apiService.get(Constants.Endpoint.user(userId));
  }

  scan(guid) {
    return this.apiService.post(Constants.Endpoint.scan(guid), {});
  }

  gallery() {
    return this.apiService.get(Constants.Endpoint.gallery(this.userStorageService.get().guid));
  }

  patientGallery(guid) {
    return this.apiService.get(Constants.Endpoint.gallery(guid));
  }

  patientProfile(guid) {
    return this.apiService.get(Constants.Endpoint.user(guid));
  }

  upload(data) {
    const formData: FormData = new FormData();
    formData.append('gallery', data, data.name);
    formData.append('user', this.userStorageService.get().guid);
    return this.apiService.postFormData(Constants.Endpoint.upload, formData);
  }

  resendQRCode(guid) {
    return this.apiService.get(Constants.Endpoint.resendQRCode(guid));
  }

  reports() {
    return this.apiService.get(Constants.Endpoint.reports);
  }

  staffReports(guid) {
    return this.apiService.get(Constants.Endpoint.staffReports(guid));
  }

  invite(data) {
    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      role: data.role
    };
    return this.apiService.post(Constants.Endpoint.invite, body);
  }

  delete() {
    return this.apiService.delete(Constants.Endpoint.delete(this.userStorageService.get().guid));
  }

  staff() {
    return this.apiService.get(Constants.Endpoint.staff);
  }

  staffProfile(guid) {
    return this.apiService.get(Constants.Endpoint.staffProfile(guid));
  }

  revoke(guid) {
    return this.apiService.delete(Constants.Endpoint.delete(guid));
  }

  resendInvitation(guid) {
    return this.apiService.post(Constants.Endpoint.resendInvitation(guid), {});
  }

  changePassword(data) {
    const body = {
      password: data.password,
      password_confirmation: data.password_confirmation,
    };
    return this.apiService.put(Constants.Endpoint.changePassword(this.userStorageService.get().guid), body);
  }

  editDetails(data) {
    const body = {
      first_name: data.first_name,
      last_name: data.last_name,
      job_title: data.job_title,
    };
    return this.apiService.put(Constants.Endpoint.editDetails(data.guid), body);
  }
}
