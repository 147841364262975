import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodsGridComponent } from '../../shared/moods-grid/moods-grid.component';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient-my-mood',
  templateUrl: './patient-my-mood.component.html',
  styleUrls: ['./patient-my-mood.component.scss']
})
export class PatientMyMoodComponent implements OnInit {

  @ViewChild('moodsGrid', {static: false}) private moodsGrid: MoodsGridComponent;

  selectedMood: {
    id: number;
  } = null;

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() { }

  onSelectMood(mood) {
    this.selectedMood = mood;
  }

  onConfirm() {
    this.apiClientService.setMood(this.selectedMood.id).subscribe(
      data => this.handleSetMoodResponse(data),
      error => this.handleError(error)
    );
  }

  private handleSetMoodResponse(response) {
    const state = {
      mood: this.selectedMood
    };
    this.router.navigateByUrl('/dashboard', { state });
  }

  private handleError(error) { }
}
