import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';

@Component({
  selector: 'app-moods-grid',
  templateUrl: './moods-grid.component.html',
  styleUrls: ['./moods-grid.component.scss'],
})
export class MoodsGridComponent implements OnInit {
  @Output() selectMood = new EventEmitter<string>();

  moods: {}[] = [];
  selectedMood: string = null;

  constructor(private apiClientService: ApiClientService) {}

  ngOnInit() {
    this.apiClientService.getMoods().subscribe(
      data => this.handleGetMoodsResponse(data),
      error => this.handleError(error)
    );
  }

  onSelect(mood) {
    if (this.selectedMood !== mood) {
      this.selectedMood = mood;
      this.selectMood.emit(this.selectedMood);
      console.log(mood);
    }
  }

  private handleGetMoodsResponse(response) {
    console.log(response);
    this.moods = response.data;
  }

  private handleError(error) { }
}
