import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MoodBigComponent } from '../../components/shared/mood-big/mood-big.component';
import { GalleryComponent } from '../dashboard/client/components/gallery/gallery.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MoodBigComponent,
    GalleryComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MoodBigComponent,
    GalleryComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
