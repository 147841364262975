import {Component, Input, OnInit} from '@angular/core';
import {Helper} from '../../../helpers/helper';

@Component({
  selector: 'app-mood-big',
  templateUrl: './mood-big.component.html',
  styleUrls: ['./mood-big.component.scss']
})
export class MoodBigComponent implements OnInit {

  @Input() mood: {
    mood: string;
  };

  get title() {
    return Helper.uppercaseFirst(this.mood.mood);
  }

  constructor() { }

  ngOnInit() { }
}
