import { Component, Input, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public errors = {
    firstName: [],
    lastName: [],
    email: []
  };

  public form = {
    firstName: null,
    lastName: null,
    email: null,
    isGuardian: '0'
  };

  private get isValid(): boolean {

    let result = true;

    if (this.form.firstName === null || this.form.firstName === '') {
      this.errors.firstName.push('Please provide your First Name');
      result = false;
    }

    if (this.form.lastName === null || this.form.lastName === '') {
      this.errors.lastName.push('Please provide your Last Name');
      result = false;
    }

    if (this.form.email === null || this.form.email === '') {
      this.errors.email.push('Please provide your e-mail address');
      result = false;
    }

    return result;
  }

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() { }

  onSend() {

    this.clearErrors();

    if (this.isValid) {
      this.apiClientService.signup(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
    }
  }

  onProceed() {

    if (this.isValid) {
      const state = {
        guardianData: this.form
      };
      this.router.navigateByUrl('/care-recipient-details', { state });
    }
  }

  onBack() {
    this.router.navigateByUrl('/login');
  }

  private handleResponse(data) {
    localStorage.setItem('checkbox-referrer', 'register');
    this.router.navigateByUrl('/check-inbox');
  }

  private handleError(error) {
    this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      firstName: [],
      lastName: [],
      email: []
    };
  }
}
