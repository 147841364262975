import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../helpers/helper';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  avatarUrl = null;
  tabs: string[] = ['My Mood', 'About Me', 'Gallery'];
  selectedTab = this.tabs[0];
  files: {}[] = [];
  patient = null;
  selectedFile = null;
  qrMessage = null;
  
  moods: {
    pivot: {
      created_at: string
    };
    id: number
    mood: string
  }[] = null;
  guid = null;

  constructor(
    private route: ActivatedRoute,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.guid = params.id;
    });
    if (this.guid) {
      this.apiClientService.patientProfile(this.guid).subscribe(
        response => this.handleGetPatientResponse(response),
        error => this.handleError(error)
      );
      this.apiClientService.getMood(this.guid).subscribe(
        response => this.handleGeMoodResponse(response),
        error => this.handleError(error)
      );
      this.fetchGallery();
    } else {
      this.router.navigateByUrl('/dashboard'); // Guard ?
    }
  }

  uppercaseTitle(title) {
    return Helper.uppercaseFirst(title);
  }

  onPrint() {
    const win = window.open('');
    win.document.write('<img src="data:image/jpeg;base64,' + this.patient.qr_code + '" onload="window.print();window.close()" alt=""/>');
    win.focus();
  }

  onEmailQRCode() {
    if (this.guid) {
      this.apiClientService.resendQRCode(this.guid).subscribe(
        response => this.handleEmailQrResponse(response),
        error => this.handleError(error)
      );
    }
  }

  onPreviewFile(file) {
    this.selectedFile = file;
    console.log(file);
  }

  onClosePreview() {
    this.selectedFile = null;
  }

  private fetchGallery() {
    this.apiClientService.patientGallery(this.guid).subscribe(
      response => this.handleGetGalleryResponse(response),
      error => this.handleError(error)
    );
  }

  private handleGetGalleryResponse(response) {
    console.log(response);
    this.files = response.data;
  }

  private handleEmailQrResponse(response) {
    this.qrMessage = 'You have successfully emailed this QR code'

    setTimeout(function() {
      this.qrMessage = null;
    }.bind(this), 5000);
  }

  private handleGeMoodResponse(response) {
    console.log(response);
    this.moods = response.data;
  }

  private handleGetPatientResponse(response) {
    console.log(response);
    this.patient = response;
    this.avatarUrl = this.patient.image.url ? this.patient.image.url : '/images/profile-pic.svg';
  }

  private handleError(error) {
    console.log(error);
  }
}
