import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestionsFormComponent } from '../../../shared/questions-form/questions-form.component';
import { ApiClientService } from '../../../../services/shared/api-client.service';
import { Router } from '@angular/router';
import {combineLatest} from "rxjs";

@Component({
  selector: 'app-setup-questions',
  templateUrl: './setup-questions.component.html',
  styleUrls: ['./setup-questions.component.scss']
})
export class SetupQuestionsComponent implements OnInit {

  questions: {
    id: number;
    answer: string;
  }[] = [];
  user: {} = null;

  get isValid() {
    const answers = this.questions.filter(question => {
      return question.answer && question.answer;
    });
    return answers.length > 0;
  }

  @ViewChild('questionsForm', {static: false}) private questionsForm: QuestionsFormComponent;

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() {
    const questionsResult = this.apiClientService.questions();
    const userResult = this.apiClientService.user();

    combineLatest([questionsResult, userResult]).subscribe(([questions, user]) => {
      this.questions = questions.data;
      this.user = user;
      this.questions.forEach(question => {
        question.answer = user.questions.filter(userQuestion => userQuestion.id === question.id)[0].pivot.answer;
      });
    }, error => {
      this.handleError(error);
    });
  }

  onNext() {
    const data = this.questionsForm.questions.map(question => {
      return {question: question.id, answer: question.answer ? question.answer : null};
    });
    this.apiClientService.answers(data).subscribe(
      response => this.handleResponse(response),
      error => this.handleError(error)
    );
  }

  onBack() {
    this.router.navigateByUrl('/setup/photo');
  }

  private handleGetQuestionsResponse(response) {
    this.questions = response.data;
  }

  private handleResponse(response) {
    this.router.navigateByUrl('/setup/mood');
  }

  private handleError(error) { }
}
