import { Component } from '@angular/core';
import { Constants } from './model/constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'To Know Me';
  templateUrl=Constants.templateUrl;
}
