import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {

  public errors = {
    firstName: [],
    lastName: [],
    jobTitle: []
  };

  public form = {
    firstName: null,
    lastName: null,
    jobTitle: null
  };

  guid = null;
  staff = null;

  private get isValid(): boolean {

    let result = true;

    if (this.form.firstName === null || this.form.firstName === '') {
      this.errors.firstName.push('Please provide staff\'s First Name');
      result = false;
    }

    if (this.form.lastName === null || this.form.lastName === '') {
      this.errors.lastName.push('Please provide staff\'s Last Name');
      result = false;
    }

    if (this.form.jobTitle === null || this.form.jobTitle === '') {
      this.errors.jobTitle.push('Please provide staff\'s Job Title');
      result = false;
    }

    return result;
  }

  constructor(
    private route: ActivatedRoute,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.guid = params.id;
    });

    this.apiClientService.staffProfile(this.guid).subscribe(
      response => this.handleGetStaffResponse(response),
      error => this.handleError(error)
    );
  }

  onBack() {
    this.router.navigateByUrl(`/staff/${this.guid}`);
  }

  onConfirm() {

    this.clearErrors();

    if (this.isValid) {

      const data = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        job_title: this.form.jobTitle,
        guid: this.guid
      };

      this.apiClientService.editDetails(data).subscribe(
        response => this.handleEditDetailsResponse(response),
        error => this.handleError(error)
      );
    }
  }

  private handleGetStaffResponse(response) {
    this.staff = response;
    this.form.firstName = this.staff.first_name;
    this.form.lastName = this.staff.last_name;
    this.form.jobTitle = this.staff.job_title;
  }

  private handleEditDetailsResponse(response) {
    this.router.navigateByUrl(`/staff/${this.guid}`);
  }

  private handleError(error) {
    this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      firstName: [],
      lastName: [],
      jobTitle: []
    };
  }
}
