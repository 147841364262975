import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from '../../../services/shared/api-client.service';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.scss']
})
export class StaffProfileComponent implements OnInit {

  isDrawerVisible = false;

  guid = null;
  reports: {}[] = [];
  staff = null;

  constructor(private route: ActivatedRoute, private router: Router, private apiClientService: ApiClientService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.guid = params.id;
    });

    this.apiClientService.staffProfile(this.guid).subscribe(
      response => this.handleGetProfileResponse(response),
      error => this.handleError(error)
    );

    this.apiClientService.staffReports(this.guid).subscribe(
      response => this.handleGetReportsResponse(response),
      error => this.handleError(error)
    );
  }

  onBack() {
    this.router.navigateByUrl('/dashboard');
  }

  onMore() {
    this.isDrawerVisible = !this.isDrawerVisible;
  }

  onEditDetails() {
    this.router.navigateByUrl(`/staff/${this.guid}/edit`);
  }

  onResendInvitation() {
    this.apiClientService.resendInvitation(this.guid).subscribe(
      response => this.handleResendInvitationResponse(response),
      error => this.handleError(error)
    );
  }

  onRevokeAccess() {
    this.apiClientService.revoke(this.guid).subscribe(
      response => this.handleRevokeResponse(response),
      error => this.handleError(error)
    );
  }

  onCancel() {
    this.isDrawerVisible = false;
  }

  private handleResendInvitationResponse(response) {
    this.isDrawerVisible = false;
    this.router.navigateByUrl('/dashboard');
  }

  private handleRevokeResponse(response) {
    this.router.navigateByUrl('/dashboard');
  }

  private handleGetReportsResponse(response) {
    console.log(response);
    this.reports = response.data;
  }

  private handleGetProfileResponse(response) {
    console.log(response);
    this.staff = response;
  }

  private handleError(error) {
    console.log(error);
  }
}
