import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public errors = {
    password: [],
    passwordConfirmation: []
  };

  public form = {
    password: null,
    passwordConfirmation: null
  };

  private get isValid(): boolean {

    let result = true;

    if (this.form.password === null || this.form.password === '') {
      this.errors.password.push('Please provide your Password');
      result = false;
    }

    if (this.form.passwordConfirmation === null || this.form.passwordConfirmation === '') {
      this.errors.passwordConfirmation.push('Please provide your Password Confirmation');
      result = false;
    }

    if (this.form.password !== this.form.passwordConfirmation) {
      this.errors.password.push('Passwords do not match');
      this.errors.passwordConfirmation.push('Passwords do not match');
      result = false;
    }

    return result;
  }

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() { }

  onBack() {
    this.router.navigateByUrl('/dashboard');
  }

  onConfirm() {

    this.clearErrors();

    if (this.isValid) {
      const data = {
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation
      };
      this.apiClientService.changePassword(data).subscribe(
        response => this.handleResponse(response),
        error => this.handleError(error)
      );
    }
  }

  private handleResponse(response) {
    this.router.navigateByUrl('/dashboard');
  }

  private handleError(error) { }

  private clearErrors() {
    this.errors = {
      password: [],
      passwordConfirmation: []
    };
  }
}
