import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Constants } from 'src/app/model/constants';
import { TokenStorageService } from '../storage/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private apiUrl = Constants.baseApiUrl;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {}

  private get httpOptions() {
    if (this.tokenStorageService.get()) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${this.tokenStorageService.get()}`,
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      };
    }
  }

  private get multipartHttpOptions() {
    if (this.tokenStorageService.get()) {
      return {
        headers: new HttpHeaders({
          Accept: 'application/json',
          enctype: 'multipart/form-data',
          Authorization: `Bearer ${this.tokenStorageService.get()}`
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          Accept: 'application/json',
          enctype: 'multipart/form-data'
        })
      };
    }
  }

  get(endpoint: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + endpoint, this.httpOptions).pipe(
      retry(1),
      catchError((err) => this.handleError(err))
    );
  }

  post(endpoint: string, data): Observable<any> {
    return this.http
      .post<any>(this.apiUrl + endpoint, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  postFormData(endpoint: string, data: FormData): Observable<any> {
    return this.http
    .post<any>(this.apiUrl + endpoint, data, this.multipartHttpOptions)
    .pipe(retry(1), catchError(this.handleError));
  }

  put(endpoint: string, data): Observable<any> {
    return this.http
      .put<any>(this.apiUrl + endpoint, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  delete(id) {
    return this.http
      .delete<any>(this.apiUrl + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  private handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      if (error.status == "422") {
      }
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (error.status === 503) {
      //this.logoutHelper.logout();
    }
    return Promise.reject(error);
  }
}
