import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  public errors = {
    email: [],
  };

  public form = {
    email: null
  };

  private get isValid(): boolean {

    let result = true;

    if (this.form.email === null || this.form.email === '') {
      this.errors.email.push('Please provide your Email');
      result = false;
    }

    return result;
  }

  constructor(private userService: ApiClientService, private router: Router) { }

  ngOnInit() { }

  onBack() {
    this.router.navigateByUrl('/login');
  }

  onReset() {

    this.clearErrors();

    if (this.isValid) {
      this.userService.forgotPassword(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
    }
  }

  private handleResponse(data) {
    localStorage.setItem('checkbox-referrer', 'forgot-password');
    this.router.navigateByUrl('/check-inbox');
    // this.router.navigateByUrl('/reset-password');
  }

  private handleError(error) {
    localStorage.setItem('checkbox-referrer', 'forgot-password');
    this.router.navigateByUrl('/check-inbox');
    //this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      email: [],
    };
  }
}
