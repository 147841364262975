import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { TokenStorageService } from '../../../services/storage/token-storage.service';
import { UserStorageService } from '../../../services/storage/user-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public errors = {
    email: [],
    password: []
  };

  public form = {
    email: null,
    password: null
  };

  constructor(
    private apiClientService: ApiClientService,
    private tokenStorageService: TokenStorageService,
    private userStorageService: UserStorageService,
    private router: Router
  ) { }

  ngOnInit() { }

  onLogIn() {
    this.clearErrors();
    this.apiClientService.login(this.form).subscribe(
      response => this.handleResponse(response),
      error => this.handleError(error)
    );
  }

  onForgottenPassword() {
    this.router.navigateByUrl('/forgotten-password');
  }

  private handleResponse(response) {
    this.clearForm();
    if (response.status_code === 200) {
      this.tokenStorageService.set(response.access_token);
      this.userStorageService.set(response.user);
      this.router.navigateByUrl('/dashboard');
    }
  }

  private handleError(error) {
    this.clearForm();
    this.errors = error.error.errors;
  }

  private clearForm() {
    this.form.email = null;
    this.form.password = null;
  }

  private clearErrors() {
    this.errors = {
      email: [],
      password: []
    };
  }
}
