import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  public errors = {
    firstName: [],
    lastName: [],
    email: []
  };

  public form = {
    firstName: null,
    lastName: null,
    email: null,
    role: 'Host Admin'
  };

  private get isValid(): boolean {

    let result = true;

    if (this.form.firstName === null || this.form.firstName === '') {
      this.errors.firstName.push('Please provide invitee First Name');
      result = false;
    }

    if (this.form.lastName === null || this.form.lastName === '') {
      this.errors.lastName.push('Please provide invitee Last Name');
      result = false;
    }

    if (this.form.email === null || this.form.email === '') {
      this.errors.email.push('Please provide invitee e-mail address');
      result = false;
    }

    return result;
  }

  constructor(private userService: ApiClientService, private router: Router) { }

  ngOnInit() {}

  onSend() {

    this.clearErrors();

    if (this.isValid) {
      this.userService.invite(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
    }
  }

  onBack() {
    this.router.navigateByUrl('/dashboard');
  }

  private handleResponse(data) {
    this.router.navigateByUrl('/dashboard');
  }

  private handleError(error) {
    this.errors = error.error.errors;
  }

  private clearErrors() {
    this.errors = {
      firstName: [],
      lastName: [],
      email: []
    };
  }
}
