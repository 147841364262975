export abstract class Constants {
  static baseApiUrl: string = window.location.port === '4200' ? 'http://localhost/api/' : window.location.origin + '/api/';
  static templateUrl: string = window.location.port === '4200' ? 'http://localhost/templates/' : window.location.origin + '/templates/';

  static Endpoint = class {
    static login = 'login';
    static invite = 'users/invite';
    static register = 'register';
    static resendVerification = 'resend-verification';
    static forgotPassword = 'forgot-password';
    static forgotPasswordVerify = 'forgot-password/verify';
    static setPassword = 'set-password';
    static resetPassword = 'reset-password';
    static moods = 'moods';
    static questions = 'questions';
    static reports = 'reports';
    static upload = 'gallery';
    static staff = 'users';

    static setAvatar(guid) {
      return `users/${guid}/avatar`;
    }
    static user(guid) {
      return `users/${guid}`;
    }
    static verify(token) {
      return `verify/${token}`;
    }
    static scan(guid) {
      return `patient/scan/${guid}`;
    }
    static myMood(guid) {
      return `moods/my-mood/${guid}`;
    }
    static answers(guid) {
      return `users/${guid}/profile`;
    }
    static resendQRCode(guid) {
      return `users/${guid}/resend-qr`;
    }
    static gallery(guid) {
      return `gallery/${guid}`;
    }
    static delete(guid) {
      return `users/${guid}`;
    }
    static staffProfile(guid) {
      return `users/${guid}`;
    }
    static staffReports(guid) {
      return `reports/${guid}`;
    }
    static resendInvitation(guid) {
      return `users/resend-invititation/${guid}`;
    }
    static changePassword(guid) {
      return `users/${guid}/password`;
    }
    static editDetails(guid) {
      return `users/${guid}`;
    }
  };
}
