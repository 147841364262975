import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-qrreader',
  templateUrl: './qrreader.component.html',
  styleUrls: ['./qrreader.component.scss']
})
export class QrreaderComponent implements OnInit {
  @Input() guid: string;

  public element={
    type:null,
    url:null,
    correctionLevel:null
  };

  constructor() { }

  ngOnInit() {
    this.element.url=window.location.origin+'/'+ this.guid;
    this.element.type=NgxQrcodeElementTypes.URL;
    this.element.correctionLevel=NgxQrcodeErrorCorrectionLevels.HIGH;
  }
}
