import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { ApiClientService } from '../../../services/shared/api-client.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {

  isDrawerVisible = false;

  user = null;

  constructor(
    private userService: UserService,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.apiClientService.user().subscribe(
      response => this.handleGetUserResponse(response),
      error => this.handleError(error)
    );
  }

  onBack() {
    this.router.navigateByUrl('/dashboard');
  }

  onDeleteMyAccount() {
    this.isDrawerVisible = true;
  }

  onDelete() {
    this.apiClientService.delete().subscribe(
      response => this.handleDeleteResponse(response),
      error => this.handleError(error)
    );
  }

  onCancel() {
    this.isDrawerVisible = false;
  }

  private handleGetUserResponse(response) {
    console.log(response);
    this.user = response;
  }

  private handleDeleteResponse(response) {
    console.log(response);
    this.userService.logout();
    //this.router.navigateByUrl('/login');
    location.href = 'login';
  }

  private handleError(error) {
    console.log(error);
  }
}
