import { Component, OnInit, OnChanges } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApiClientService } from '../../../services/shared/api-client.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnChanges {

  public errors = {
    password: [],
    passwordConfirmation: []
  };

  public form = {
    password: null,
    passwordConfirmation: null
  };

  private get isValid(): boolean {

    let result = true;

    if (this.form.password === null || this.form.password === '') {
      this.errors.password.push('Please provide your Password');
      result = false;
    }

    if (this.form.passwordConfirmation === null || this.form.passwordConfirmation === '') {
      this.errors.passwordConfirmation.push('Please provide your Password Confirmation');
      result = false;
    }

    if (this.form.password !== this.form.passwordConfirmation) {
      this.errors.password.push('Passwords do not match');
      this.errors.passwordConfirmation.push('Passwords do not match');
      result = false;
    }

    return result;
  }

  private email: null;
  private token: null;

  private hasError: boolean = true;
  
  private checks: boolean = false;

  constructor(private route: ActivatedRoute, private userService: ApiClientService, private router: Router) {
    this.userService.verifyForgotPassword({
      email : this.route.snapshot.paramMap.get('email'),
      token : this.route.snapshot.paramMap.get('token'),
    }).subscribe((response) => {
        this.hasError = false;
        this.checks = true;
      },
      (error) => {
        this.checks = true;
        this.hasError = true;
      }
    );
  }

  ngOnInit() {
    //console.log(this.route.snapshot.paramMap.get('email'));

    this.route.params.subscribe(params => {
      this.email = params.email;
      this.token = params.token;
      
      /* this.userService.verifyForgotPassword({
        email : this.email,
        token : this.token,
      }).subscribe((response) => {
          this.hasError = false;
        },
        (error) => {
          this.hasError = true;
        }
      ); */

    });
  }

  ngOnChanges() {
    //
  }

  onConfirm() {

    this.clearErrors();

    if (this.isValid) {
      const data = {
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
        token: this.token,
        email: this.email
      };

      this.userService.resetPassword(data).subscribe(
        response => this.handleResponse(response),
        error => this.handleError(error)
      );
    }
  }

  onResend() {
    this.userService.forgotPassword({
      email : atob(this.email)
    }).subscribe(
      response => this.handleResendVerificationResponse(response),
      error => this.handleError(error)
    );
  }

  private handleResponse(response) {
    this.router.navigateByUrl('/login');
  }
  
  private handleResendVerificationResponse(response) {
    localStorage.setItem('checkbox-referrer', 'reset-password');
    this.router.navigateByUrl('/check-inbox');
  }

  private handleError(error) { }

  private clearErrors() {
    this.errors = {
      password: [],
      passwordConfirmation: []
    };
  }
}
