import { Injectable } from '@angular/core';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  get(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  set(user) {
    localStorage.setItem('user', JSON.stringify(this.map(user)));
  }

  clearCurrentUser() {
    localStorage.removeItem('user');
  }

  private map(data) {
    const user = new User();
    user.id = data.id;
    user.guid = data.guid;
    user.firstName = data.first_name;
    user.lastName = data.last_name;
    user.fullName = data.full_name;
    user.email = data.email;
    user.organisationID = data.organisation_id;
    user.role = data.role;
    return user;
  }
}
