import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { LoginComponent } from './components/users/login/login.component';
import { SignupComponent } from './components/users/signup/signup.component';
import { CheckInboxComponent } from './components/users/check-inbox/check-inbox.component';
import { SetPasswordComponent } from './components/users/set-password/set-password.component';
import { ForgottenPasswordComponent } from './components/users/forgotten-password/forgotten-password.component';
import { SetupPhotoComponent } from './components/patient/setup/photo/setup-photo.component';
import { SetupQuestionsComponent } from './components/patient/setup/questions/setup-questions.component';
import { SetupMoodComponent } from './components/patient/setup/mood/setup-mood.component';
import { SetupSummaryComponent } from './components/patient/setup/summary/setup-summary.component';
import { VerifyComponent } from './components/users/verify/verify.component';
import { PatientMyMoodComponent } from './components/patient/my-mood/patient-my-mood.component';
import { CareRecipientDetailsComponent } from './components/users/care-recipient-details/care-recipient-details.component';
import { PatientAboutMeComponent } from './components/patient/about-me/patient-about-me.component';
import { IsLoggedInGuard } from './guards/is-logged-in-guard.service';
import { HasStateTokenGuard } from './guards/has-state-token.guard';
import { BeforeLogInGuard } from './guards/before-log-in.guard';
import { IsClientGuard } from './guards/is-client-guard.service';
import { IsStaffGuard } from './guards/is-staff.guard';
import { IsAdminGuard } from './guards/is-admin.guard';
import { PatientProfileComponent } from './components/staff/patient-profile/patient-profile.component';
import { StaffProfileComponent } from './components/admin/staff-profile/staff-profile.component';
import { InviteComponent } from './components/admin/invite/invite.component';
import { ScanComponent } from './components/staff/scan/scan.component';
import { ResetPasswordComponent } from './components/users/reset-password/reset-password.component';
import { ManageAccountComponent } from './components/patient/manage-account/manage-account.component';
import { EditDetailsComponent } from './components/admin/edit-details/edit-details.component';
import { ChangePasswordComponent } from './components/users/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'sign-up',
    component: SignupComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'care-recipient-details',
    component: CareRecipientDetailsComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'verify/:email/:token',
    component: VerifyComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'check-inbox',
    component: CheckInboxComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'forgotten-password/:email/:token',
    component: ResetPasswordComponent,
    canActivate: [BeforeLogInGuard]
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [BeforeLogInGuard, HasStateTokenGuard]
  },
  {
    path: 'setup/photo',
    component: SetupPhotoComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'setup/questions',
    component: SetupQuestionsComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'setup/mood',
    component: SetupMoodComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'setup/summary',
    component: SetupSummaryComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'my-mood',
    component: PatientMyMoodComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'about-me',
    component: PatientAboutMeComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'manage-account',
    component: ManageAccountComponent,
    canActivate: [IsLoggedInGuard, IsClientGuard]
  },
  {
    path: 'scan',
    component: ScanComponent,
    canActivate: [IsLoggedInGuard, IsStaffGuard]
  },
  {
    path: 'patient/:id',
    component: PatientProfileComponent,
    canActivate: [IsLoggedInGuard, IsStaffGuard]
  },
  {
    path: 'staff/:id',
    component: StaffProfileComponent,
    canActivate: [IsLoggedInGuard, IsAdminGuard]
  },
  {
    path: 'invite',
    component: InviteComponent,
    canActivate: [IsLoggedInGuard, IsAdminGuard]
  },
  {
    path: 'staff/:id/edit',
    component: EditDetailsComponent,
    canActivate: [IsLoggedInGuard, IsAdminGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [IsLoggedInGuard, IsAdminGuard]
  },
  {
    path: '404',
    component: PagenotfoundComponent,
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
