import { Injectable } from '@angular/core';
import { UserStorageService } from '../storage/user-storage.service';
import { User } from '../../model/user';
import { Role } from '../../enum/roles';
import { TokenStorageService } from '../storage/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private get currentUser(): User {
    return this.userStorageService.get();
  }

  constructor(private userStorageService: UserStorageService, private tokenStorageService: TokenStorageService) { }

  get isClient() {
    return this.currentUser && this.currentUser.role === Role.Client;
  }

  get isStaff() {
    return this.currentUser && this.currentUser.role === Role.Staff;
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  logout() {
    this.userStorageService.clearCurrentUser();
    this.tokenStorageService.remove();
  }
}
