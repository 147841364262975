export class Helper {

  static uppercaseFirst(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  public validatePassword(password: string) {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length >= 6;
  }
}
