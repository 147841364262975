import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiClientService } from '../../../services/shared/api-client.service';
import { QuestionsFormComponent } from '../../shared/questions-form/questions-form.component';
import { Router } from '@angular/router';
import { combineLatest, zip } from 'rxjs';

@Component({
  selector: 'app-patient-about-me',
  templateUrl: './patient-about-me.component.html',
  styleUrls: ['./patient-about-me.component.scss']
})
export class PatientAboutMeComponent implements OnInit {

  questions: {
    id: number;
    answer: string;
  }[] = [];
  user: {} = null;

  public previousRoute: string = null;

  @ViewChild('questionsForm', {static: false}) private questionsForm: QuestionsFormComponent;

  constructor(private apiClientService: ApiClientService, private router: Router) { }

  ngOnInit() {
    // this.previousRoute = history.state.data.previousRoute;

    const questionsResult = this.apiClientService.questions();
    const userResult = this.apiClientService.user();

    combineLatest([questionsResult, userResult]).subscribe(([questions, user]) => {
      this.questions = questions.data;
      this.user = user;
      this.questions.forEach(question => {
        question.answer = user.questions.filter(userQuestion => userQuestion.id === question.id)[0].pivot.answer;
      });
    }, error => {
      this.handleError(error);
    });
  }

  onConfirm() {
    const data = this.questionsForm.questions.map(question => {
      return {question: question.id, answer: question.answer ? question.answer : null};
    });
    this.apiClientService.answers(data).subscribe(
      response => this.handleSetAnswersResponse(response),
      error => this.handleError(error)
    );
  }

  private handleGetQuestionsResponse(response) {
    this.questions = response.data;
  }

  private handleSetAnswersResponse(response) {
    this.router.navigateByUrl('/dashboard');
  }

  private handleError(error) { }
}
