import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiClientService } from '../../../../services/shared/api-client.service';

@Component({
  selector: 'app-setup-photo',
  templateUrl: './setup-photo.component.html',
  styleUrls: ['./setup-photo.component.scss']
})
export class SetupPhotoComponent implements OnInit {

  @ViewChild('avatar', {static: false}) private avatar: ElementRef;

  avatarUrl = null;

  constructor(private router: Router, private apiClientService: ApiClientService) { }

  ngOnInit() { }

  onNext() {
    this.router.navigateByUrl('/setup/questions');
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    this.preview(file);
    this.apiClientService.setAvatar(file).subscribe(
      response => this.handleSetAvatarResponse(response),
      error => this.handleError(error)
    );
  }

  preview(file) {

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.avatarUrl = reader.result;
    };
  }

  onUpload() { }

  private handleSetAvatarResponse(response) {
    console.log(response);
  }

  private handleError(error) {
    console.log(error);
  }
}
